import { Injectable } from "@angular/core";
import { Device } from "../models/device.model";
import * as _ from "lodash";
import { OcgKnxCommands } from "./knx.service";
import { KnxInterface } from "./knx.service";

@Injectable({
    providedIn: 'root'
})

export class InterfacesSetupService
{
    public setupInterface (devices: Device[]) {
        let newDevices: Device[];

        newDevices = _.map(devices, (device:Device) => {
            const router = device.router;
           const commandsRes = device.commands;
           device.interfaces = {
               "DPST-1-1": null,
               "DPST-3-7": null,
               "DPST-5-1": null
           }
          let grouppedCommands: any  = _.groupBy(commandsRes, "dpst");

          
           _.forEach(grouppedCommands, (commands, key) => {
               let grouppedCommandsByType = _.groupBy(commands, "type");
               device.interfaces[key] = {cmd: null, fb: null, currentValueRaw: null};               
                _.forEach(grouppedCommandsByType, (command, value) => {
                    device.interfaces[key][value] = command.pop();
                    
                })
           })
        
           return device;
        })

        return newDevices;
        
        
    }

    public setup_commands (analog_commands, type:string, dpst:string): KnxInterface | null {
        let command;
        _.forEach(analog_commands, (analog_command) => {
            if (analog_command.type === type && analog_command.dpst === dpst) {
                return analog_command;
            }
        })
        return null;
    }
}