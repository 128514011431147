import { Component, OnDestroy, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { Store } from "@ngrx/store";
import { Subscription } from "rxjs";
import { skipWhile, take, tap } from "rxjs/operators";
import { AppInit } from "../services/init/app-init.service";
import * as fromApp from "../store/app.reducer";

@Component({
  selector: "app-error",
  templateUrl: "error.component.html",
  styleUrls: ["error.component.css"],
})
export class ErrorComponent implements OnInit, OnDestroy {
  roomId: number = null;
  roomHash: string = null;
  canControl: boolean = null;
  private StoreSub: Subscription;
  roomUrls: string[] = [];
  ngOnInit() {
    this.StoreSub = this.store
      .select("appInit")
      .pipe(
        tap(appInit => {
          this.roomId = appInit.roomId;
          this.roomHash = appInit.roomHash;
          this.canControl = appInit.canControl;
        }),
        skipWhile((appInit) => !appInit.canControl),
        tap(() => {
          // this.router.navigate(["/", "roomAreas"]);
        })
      )
      .subscribe();
      this.roomUrls.push(this.initService.currentUrl) ;
  }

  ngOnDestroy() {
    if (this.StoreSub) {
      this.StoreSub.unsubscribe();
    }
  }
  constructor(private store: Store<fromApp.AppState>, private router: Router, private initService: AppInit) {}
}
