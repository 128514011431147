import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from "@angular/router";
import { Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class BarcodeGuard implements CanActivate {
  canActivate(
    route: ActivatedRouteSnapshot,
    router: RouterStateSnapshot
  ): boolean | UrlTree | Promise<boolean | UrlTree> | Observable<boolean | UrlTree> {
    let url = decodeURI(window.location.href);
    if (url.split("|").length !== 4 && url.split("|").length !== 6) {
      return this.router.createUrlTree(["/", "error"]);
    } else if (url.split("|").length === 4) {
      return this.router.createUrlTree(["/", "roomAreas"]);
    }else if (url.split("|").length === 6) {
      return true;
    }
  }

  constructor(private router: Router) {}
}
