import { NgModule } from "@angular/core";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { StoreDevtoolsModule } from "@ngrx/store-devtools";
import { BrowserModule } from "@angular/platform-browser";
import { RouterModule, Routes } from "@angular/router";
import { EffectsModule } from "@ngrx/effects";
import { StoreModule } from "@ngrx/store";
import { AppComponent } from "./app.component";
import { RoomComponent } from "./room/room.component";
import { RoomsListComponent } from "./rooms-list/rooms-list.component";
import { LoginComponent } from "./login/login.component";
import { DeviceComponent } from "./room/device/device.component";
import { ErrorComponent } from "./error/error.component";
import * as fromApp from "./store/app.reducer";
import { AuthEffects } from "./services/auth/store/auth.effects";
import { AuthInterceptorService } from "./services/auth/auth-interceptor.service";
import { MiddlewareInterceptorService } from "./services/middleware.interceptor.service";
import { LoadingSpinner } from "./shared/loading-spinner/loading-spinner.component";
import { BarcodeGuard } from "./error/barcodeRoute.guard";
import { AppGuard } from "./error/app.guard";
import { RoomsListResolver } from "./rooms-list/rooms-listresolver.service";
import { RoomsListEffects } from "./rooms-list/store/rooms-list.effects";
import { RoomResolver } from "./room/room-resolver.service";
import { RoomEffects } from "./room/store/room.effects";
import { MiddlewareEffects } from "./services/knxmiddleware/store/knxmiddleware.effects";
import { FillPipe } from "./room/room.component";
import { AppInitEffects } from "./services/init/store/appinit.effects";
import { DEFAULT_TIMEOUT } from "./services/middleware.interceptor.service";
import { SplideComponent, OcgSlideItemElement } from './splide/splide.component';
import { OCGSplideSlideComponent } from "./splide/splide-item.component";

const appRoutes: Routes = [
  { path: "roomAreas",canActivate: [AppGuard], component: RoomsListComponent, resolve: [RoomsListResolver] },
  { path: "roomAreas/:slug", canActivate: [AppGuard],  component: RoomComponent,resolve: [RoomResolver] },
  // { path: "login", canActivate: [LoginGuard], component: LoginComponent },
  { path: "r", component: RoomComponent, canActivate: [BarcodeGuard], resolve:[RoomResolver] },
  { path: "error", component: ErrorComponent },
  { path: "**", component: ErrorComponent },
];

@NgModule({
  declarations: [AppComponent, RoomsListComponent, RoomComponent, DeviceComponent, LoginComponent, ErrorComponent, LoadingSpinner, FillPipe, SplideComponent, OCGSplideSlideComponent, OcgSlideItemElement],
  imports: [
    BrowserModule,
    HttpClientModule,
    RouterModule.forRoot(appRoutes),
    StoreModule.forRoot(fromApp.appReducer),
    StoreDevtoolsModule.instrument(),
    EffectsModule.forRoot([AuthEffects, RoomsListEffects, RoomEffects, MiddlewareEffects, AppInitEffects]),
  ],
  providers: [
    [{
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptorService,
      multi: true,
    }],
      [{
      provide: HTTP_INTERCEPTORS,
      useClass: MiddlewareInterceptorService,
      multi: true,
    }],
    [{
      provide: DEFAULT_TIMEOUT,
      useValue: 5000
    }],
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
