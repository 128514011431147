<div class = "control align-self-stretch flex-grow-1 d-flex flex-column justify-content-center align-items-center position-relative" [ngStyle]="{'background-color': isTv? 'rgba(0, 0, 0, 0.7)': ''}">
    <div class="rnd_switch-container" [ngClass]="{'on': this.binaryStatus}">
      <div class="poweroff_switch">
        <label>
          <i></i>
        </label>
      </div>
    </div>
    <div class="container-fluid align-self-start mt-3" *ngIf="deviceProperties.width === 2">
      <div class="row d-flex justify-content-center">
        <div class="col-6 py-lg-5 pb-3">
          <div class="icon_container" [ngStyle]="{'-webkit-mask-image': 'url('+ icon, opacity: opacity}" [ngClass]="{'on': this.binaryStatus}" (click)="onSwitch()">
          <img [src]="icon" class="w-100" alt="" style="visibility: hidden;" />
          </div>
        </div>
        <div class="col-5 d-flex flex-column align-items-center justify-content-around powerangeroot"> <!-- *ngIf="device.interface.includes('dimmable')" -->
          <span class="text-xl-center d-block text-muted power_level" *ngIf="dimmer.cmd">{{dimValuePrc}}%</span>
          <input type="text" class="js-range" *ngIf="dimmer.cmd" #powerange (change)="onInputchangePercent($event.target)"/>
        </div>
      </div>
    </div>
    <div class="mb-4 mt-5 w-50 d-flex flex-column align-items-center justify-content-around powerangeroot" *ngIf="deviceProperties.width === 1">
      <div class="align-self-stretch" [ngClass]="{'focusable': isTv}" [tabindex]="isTv ? '-1': ''"  (click)="onSwitch()" (keydown.enter)="onSwitch()" (focus)="addScale($event)" (blur)="removeScale($event)">
        <div [ngStyle]="{'-webkit-mask-image': 'url('+ icon, opacity: opacity}" class="icon_container" [ngClass]="{'on': this.binaryStatus}">
      <img [src]="icon ? icon : ''" class="img-fluid" alt="" style="visibility: hidden;" />
        </div>
      </div>
      <span class="device-type text-center text-color py-3">{{deviceProperties.name}}</span>
      <span class="text-xl-center d-block text-muted power_level pb-3" *ngIf="dimmer.cmd">{{dimValuePrc}}%</span>
      <input type="text" class="js-range" *ngIf="dimmer.cmd" #powerange (change)="onInputchangePercent($event.target)"/>
    </div>
  </div>