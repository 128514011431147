import { Action } from "@ngrx/store";
import { KnxCommand } from "src/app/services/knx.service";

export const MIDDLEWARE_AUTH_START = "[Middleware] Auth Start";
export const MIDDLEWARE_AUTH_SUCCESS = "[Middleware] Auth Success";
export const MIDDLEWARE_AUTH_FAIL = "[Middleware] Auth Fail";
export const DEVICE_SEND_KNX_CMD = "[Device] Send KNX Command";
export const DEVICE_SET_KNX_STATUS = "[Device] Set KNX Status";
export const GET_INTERFACE_STATUS = "[Device] Get Interface Status";

export class MiddlewareAuthStart implements Action {
  readonly type = MIDDLEWARE_AUTH_START;
}

export class MiddlewareAuthSuccess implements Action {
  readonly type = MIDDLEWARE_AUTH_SUCCESS;

  constructor(public payload: { token: string }) {}
}

export class MiddlewareAuthFail implements Action {
  readonly type = MIDDLEWARE_AUTH_FAIL;
}

export class SendKnxCommand implements Action {
  readonly type = DEVICE_SEND_KNX_CMD;

  constructor(public payload: { cmd: KnxCommand; value: any; fb?: any; deviceId: number; roomAreaId: number }) {}
}

export class SetDeviceStatus implements Action {
  readonly type = DEVICE_SET_KNX_STATUS;

  constructor(public payload: { fb: string; value: number; deviceId: number; roomAreaId: number; dpst: string; fromSocket?: {} }) {}
}

export class GetInterfaceStatus implements Action {
  readonly type = GET_INTERFACE_STATUS;

  constructor(public payload: { ip: string; status: string; dpt: string, roomAreaId: number }) {}
}

export type MiddlewareActions = MiddlewareAuthStart | MiddlewareAuthSuccess | MiddlewareAuthFail | SendKnxCommand | SetDeviceStatus;
