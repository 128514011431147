import { Component, OnDestroy, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { Store } from "@ngrx/store";
import { of, Subscription } from "rxjs";
import { mergeMap, withLatestFrom } from "rxjs/operators";
import { RoomArea } from "../models/room.model";
import * as fromApp from "../store/app.reducer";

@Component({
  selector: "app-rooms-list",
  templateUrl: "rooms-list.component.html",
  styleUrls: ["rooms-list.component.css"],
})
export class RoomsListComponent implements OnInit, OnDestroy {
  private storeSub: Subscription;
  rooms: RoomArea[] = [];
  theme:string = "dark";

  constructor(private store: Store<fromApp.AppState>, private router: Router) {}

  ngOnInit() {
    this.storeSub = this.store.select("roomAreas").pipe(
      withLatestFrom(this.store.select("appInit")),
      mergeMap(([roomAreas, appInit]) => {
        return of({
          roomAreas,
          appInit
        });
      })
    ).subscribe((multiData) => {        
      this.theme = multiData.appInit.theme  === 'dark'? 'rgb(45, 44, 56)': 'white' ;
      this.rooms = Object.values(multiData.roomAreas);
    });    
  }

  ngOnDestroy() {
    if (this.storeSub) {
      this.storeSub.unsubscribe();
    }
  }
}
