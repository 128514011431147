import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import * as fromApp from "../../store/app.reducer";
import * as AuthAction from "../auth/store/auth.actions"


export interface AuthResponseData {
    token: string,
    user_email:string,
    user_nicename:string,
    user_display_name:string,
    expiration_time:number
}

export interface AuthFailData {
    code:string,
    message:string,
    data:{
        status:number
    }
}

@Injectable({
    providedIn: "root"
})

export class AuthService {
    isValidUser:boolean = false;
    userEmail:string = null;
    private tokenExpirationTimer: any;

    setLogoutTimer(expirationDuration: number) {
        this.tokenExpirationTimer = setTimeout(() => {
          this.store.dispatch(new AuthAction.Logout());
        }, expirationDuration);
      }

      clearLogoutTimer() {
        if (this.tokenExpirationTimer) {
          clearTimeout(this.tokenExpirationTimer);
          this.tokenExpirationTimer = null;
        }
      }

      constructor( private store: Store<fromApp.AppState>){}
}