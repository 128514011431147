import { Action } from "@ngrx/store";


export const LOGIN_START = "[Auth] Login Start";
export const AUTHENTICATE_SUCCESS = "[Auth] Login";
export const AUTO_LOGIN = "[Auth] AutoLogin";
export const AUTHENTICATE_FAIL = "[Auth] Fail";
export const LOGOUT = "[Auth] Logout";


export class LoginStart implements Action {
    readonly type = LOGIN_START;

    constructor(public payload: {email: string, password: string}) {}
}

export class AuthenticateSuccess implements Action {
    readonly type = AUTHENTICATE_SUCCESS

    constructor (public payload: {username: string, token: string, expirationTime: Date, redirect: boolean}){}
}

export class AuthLogin implements Action {
    readonly type = AUTO_LOGIN;
}

export class AuthenticateFail implements Action {
    readonly type = AUTHENTICATE_FAIL;

    constructor(public payload: {message:string, status?: number}){}
}

export class Logout implements Action{
    readonly type = LOGOUT;
}


export type AuthActions = LoginStart | AuthenticateSuccess | AuthenticateFail | AuthLogin | Logout;