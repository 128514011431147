<div
  class="room-container"
  [ngClass]="{ dark: theme === 'dark', light: theme !== 'dark', tv: isTv }"
  [ngStyle]="{ 'background-image': 'url(' + ocgImage + ')' }"
>
  <div class="container-fluid">
    <nav class="top-nav text-color" *ngIf="!isTv">
      <h5 class="top-nav__prev-title" [routerLink]="['/', 'roomAreas']">Room Control</h5>
    </nav>
    <div class="top-nav__title" [ngStyle]="{'display': !isTv ? 'block': 'none'}">
      <h3 class="text-color">{{ roomAreaTitle }}</h3>
    </div>
  </div>
  <main class="main container-fluid py-3" [ngClass]="{ tv: isTv }">
    <div class="container pt-5 mt-5">
      <div class="row">
        <div class="col-12">
        <div class="control p-2 d-flex align-items-center rounded" *ngIf="!isTv">
          <div class="control__name">
            <span class="text-color">Dark Mode</span>
          </div>
          <label class="form-switch mb-0 ml-auto d-flex align-items-center">
            <input type="checkbox" (click)="switchTheme()" [checked]="theme !== 'dark'" />
            <i></i
          ></label>
        </div>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="row">
        <div class="col-12 knx-types mt-3">
          <app-splide #ocgSlide [isTv]="isTv" [options]="{
              type: 'slide',
              start: isActive,
              keyboard: 'focused',
              pagination: false,
              gap: 20,
              breakpoints: {
              '480': {
                perPage: 2,
                arrows: deviceTypes.length > 2
              },
              '1920': {
                    perPage: 4,
                    perMove: 1,
                    arrows: deviceTypes.length > 4 && !isTv
                  }
                }
              }">
              <splide-slide *ngFor="let deviceType of deviceTypes; let i = index">
                <div (click)="selectDeviceType(deviceType, i)" (keydown.enter)="selectDeviceType(deviceType, i)" [ngClass]="{'focusable': isTv, 'is-active': isActive == i}" tabindex="-1">
                  <h2 class="group-heading py-3 m-0">{{deviceType}}</h2>
                </div>
              </splide-slide>
            </app-splide>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="d-flex justify-content-center row">
        <ng-template [ngIf]="roomData !== undefined && roomData.full.length > 0">
          <app-device
            *ngFor="let device of roomData.full; trackBy: trackBy"
            [deviceProperties]="device"
            [roomAreaId]="roomAreaId"
            [ngClass]="{ 'col-12 mt-3 d-flex align-items-center position-relative': true }"
            [ngStyle]="{'display': device.inView ? 'flex': 'none'}"
          ></app-device>
        </ng-template>
      </div>
    </div>
    <div class="container">
      <div class="d-flex justify-content-center row">
        <ng-template [ngIf]="roomData !== undefined && roomData.half.length > 0">
          <app-device
            *ngFor="let device of roomData.half; trackBy: trackBy"
            [deviceProperties]="device"
            [roomAreaId]="roomAreaId"
            [ngClass]="{ 'col-6 col-md-3 justify-content-center align-items-center position-relative mt-3': true }"
            [ngStyle]="{'display': device.inView ? 'flex': 'none','min-height': '277px'}"
          ></app-device>
        </ng-template>
      </div>
    </div>
  </main>
</div>
