<div class="before_content d-flex justify-content-center align-items-center" style="height: 100vh;">
    <div class="container col-12 col-md-5 px-5 px-md-0 text-center">
      <div class="logo d-inline-block">
        <img src="assets/images/ocgpro_vertical.png" alt="OCG logo"/>
      </div>
      <div class="activation_code">
        <h1>Please Login<br></h1>
        <div class="form-group">
            <input type="text" class="form-control" placeholder="Enter username or email" #username>
        </div>
        <div class="form-group mt-2">
            <input type="password" class="form-control" placeholder="Enter password" #password>
        </div>
        <button class="btn btn-success w-100" type="button" (click)="authenticate(username, password)">Authenticate</button>
      </div>
    </div>
  </div>