import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";

export interface SwitchCmd {
    device: string,
    value: number
}

@Injectable({
    providedIn: 'root'
})


export class SwitchService {

    constructor(private http:HttpClient){}
   
    sendBinaryKnxCommand (switchCmd: SwitchCmd) {
        this.http.post<any>("http://192.168.128.72:8080/middleware-knx-command", switchCmd).subscribe();
    }
}