import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import * as fromApp from "../../store/app.reducer";
import * as AppInitActions from "./store/appinit.actions";
import { SocketService } from "../sockets.service";

@Injectable({
  providedIn: "root",
})
export class AppInit {
  public currentUrl: string;
  public SN: any;
  public isTv: boolean = false;
  public roomAreaId: number = null;
  runInit() {
    this.setRoomIdFromBarcode();
    // this.store.dispatch(new middlewareActions.MiddlewareAuthStart());
    this.socketService.initSocket();
    this.socketService.initSocketCommands();
    if (this.isTv) {
      this.startTvNavigationListeners();
      this.SN = (window as any).SpatialNavigation;
      this.SN.init();
      this.SN.add({
        selector: ".focusable",
        id: "focusEl",
        rememberSource: true,
        restrict: "none",
      });
      this.SN.add({
        selector: ".splide-focus",
        id: "spildeFoc",
        rememberSource: true,
        restrict: "self-first",
      });
      this.SN.add({
        selector: ".js-range",
        id: "powerbar",
        rememberSource: true,
        restrict: "self-only",
      });
        this.SN.add(
          {
            selector: ".range-handle.focusable",
            id: "handleBar",
            rememberSource: true,
            restrict: "self-only",
          },
        );
    }
  }

  setRoomIdFromBarcode() {

    let OcgBarcodeUrl: string = this.currentUrl;
    let roomId: number;
    let roomHash: string;
    let roomAreaId: number;
    let isTv: boolean;

    if (this.currentUrl.includes("/r=")) {

      roomId = +OcgBarcodeUrl.split("|")[1];

      isTv = OcgBarcodeUrl.split("|").length > 4 ? true : false;      
      this.isTv = isTv;

      if (OcgBarcodeUrl.split("|").length > 4) {
        roomAreaId = +OcgBarcodeUrl.split("|")[5];
        this.roomAreaId = roomAreaId;
      }

      if (roomId) {
        this.store.dispatch(new AppInitActions.SetRoomId({ roomId: roomId }));
      }
      roomHash = OcgBarcodeUrl.split("|")[3];

      if (roomHash && roomAreaId !== null) {
        this.store.dispatch(new AppInitActions.SetRoomHash({ roomId: roomId, hash: roomHash, roomAreaId: roomAreaId }));
      } else {
        this.store.dispatch(new AppInitActions.SetRoomHash({ roomId: roomId, hash: roomHash, roomAreaId: null }));
      }
    }
  }

  startTvNavigationListeners() {
    window.addEventListener('sn:navigatefailed', (e:any)=>{
      if(e.target.id.includes('slot')){
        return
      }
      if(e.detail.direction == 'left'){
        window.top.postMessage('back','*');
      }else if(e.detail.direction == 'right'){
        window.top.postMessage('showmenu','*');
      }else if(e.detail.direction == 'up'){
        window.top.postMessage('message','*');
      }else if(e.detail.direction == 'down'){
        window.top.postMessage('back','*');
      }
    });
 
    window.addEventListener('message', (msg)=>{
      if(msg.data == 'focusIn'){
 
        this.SN.focus('focusEl');
      }
    });
  }

  public backButtonCodes(){
    switch(this.isTv){
      case true:
        return [10009, 461, 88, 45, 1001];
      default:
        return [27];
    }
  }

  constructor(private store: Store<fromApp.AppState>, private socketService: SocketService) {
    this.currentUrl = decodeURI(window.location.href);
  }
}
