import { AfterContentInit, AfterViewInit, Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { Device } from "src/app/models/device.model";
import { KNXService, OcgKnxCommands } from "src/app/services/knx.service";
import { KnxInterface } from "src/app/services/knx.service";
import { of, Subscription } from "rxjs";
import { Store } from "@ngrx/store";
import * as fromApp from "src/app/store/app.reducer";
import { EventEmitter } from "@angular/core";
import { AppInit } from "src/app/services/init/app-init.service";
import { HttpClient } from "@angular/common/http";
import { catchError, take } from "rxjs/operators";
import { Control } from "src/app/models/control.model";
import * as _ from "lodash";

declare let Powerange: any;

@Component({
  selector: "app-device",
  templateUrl: "device.component.html",
  styleUrls: ["device.component.css"],
})
export class DeviceComponent implements OnInit, AfterViewInit, AfterContentInit, OnDestroy {
  @Input("deviceProperties") deviceProperties: Device;
  @Input("roomAreaId") roomAreaId: number;
  @ViewChild("powerange") powerangeInput: ElementRef;
  binaryStatus: boolean = null;
  opacity: number = 1;
  dimValuePrc: number;
  commands: OcgKnxCommands[] = null;
  dimmer: KnxInterface = null;
  binarySwitch: KnxInterface = null;
  type: string = "";
  icon: string;
  isTv: boolean;
  private dimmerReqValue: number = null;
  private routerAddress: string = "";
  private routerPort: number;
  private powerange: any = null;
  private powerangeTimeout: any = null;
  private DeviceState: Subscription;
  private IconSubscription: Subscription;
  private responseFailedEmmiter: EventEmitter<{status:boolean, hasFeedback: boolean}> = new EventEmitter();
  private doorLockEmmiter: EventEmitter<KnxInterface> = new EventEmitter();
  private responseTimeout;
  interfaces: {
    'DPST-1-1': KnxInterface | null,
    'DPST-3-7': KnxInterface | null,
    'DPST-5-1': KnxInterface | null
  }
  controls: Control;

  ngOnInit() {
    this.isTv = this.appInit.isTv;
    this.selectLocalSvg(this.deviceProperties.image);  
    this.interfaces = this.deviceProperties.interfaces;
    this.routerAddress = this.deviceProperties.router.address;
    this.routerPort = this.deviceProperties.router.port;
    this.DeviceState = this.store.select("roomDevices").subscribe((devices) => {
      for (const [key, value] of Object.entries(devices)) {
        value.forEach((device, index) => {          
          if (+device.id === +this.deviceProperties.id) {
            clearTimeout(this.responseTimeout);
            this.interfaces = device.interfaces  
            this.controls = this.knxService.assignInterfacesToControls(device.interfaces)
            if (this.controlHasFeedback(this.controls, "dimmer")) {
              
              if (this.dimmerReqValue === null) {
                this.dimValuePrc = device.dimmingStatus !== null ? device.dimmingStatus : 0;
                //this.dimValuePrc = this.knxService.reverseValue(this.controls.dimmer.fb.dpst, this.dimValuePrc);
              } else {
                this.dimValuePrc =
                  device.dimmingStatus !== null && device.dimmingStatus === this.dimmerReqValue ? device.dimmingStatus : this.dimmerReqValue;
                  //this.dimValuePrc = this.knxService.reverseValue(this.controls.dimmer.fb.dpst, this.dimValuePrc);
                  //this.dimValuePrc = this.binaryStatus === false ? 0 : this.dimValuePrc; 
              }   
            }
            
            if (this.controlHasFeedback(this.controls, "dimmer")) {
            this.dimValuePrc = _.has(this.controls.dimmer, "cmd") ? this.knxService.convertValueByDpst(this.controls.dimmer.cmd, +this.controls.dimmer.currentValueRaw): 0;
           
            //this.dimValuePrc = this.knxService.reverseValue(this.controls.dimmer.fb.dpst, this.dimValuePrc);
            setTimeout(() => {
              
              this.setPowerRange(this.dimValuePrc);
            })
            this.opacity = this.setOpacity(+this.dimValuePrc);
            } else {
              this.dimValuePrc = 0;
            }
            if (this.controlHasFeedback(this.controls, "binarySwitch")) {
             this.binaryStatus =  device.interfaces["DPST-1-1"]?.currentValueRaw !== null ?  +device.interfaces["DPST-1-1"]?.currentValueRaw === 1: false;
          }

            if (this.controlHasFeedback(this.controls, "increment")) {
              this.binaryStatus = device.interfaces["DPST-3-7"].currentValueRaw !== null ?  +device.interfaces["DPST-3-7"].currentValueRaw === 1: false;
              }
          }
        });
      }
    });
    this.responseFailedEmmiter.subscribe((val) => {
      if (!val.status && val.hasFeedback) {
        this.dimValuePrc = this.deviceProperties.dimmingStatus !== null ? this.deviceProperties.dimmingStatus : 0;
        this.binaryStatus = this.deviceProperties.binaryStatus !== null ? this.deviceProperties.binaryStatus : false;
        this.setPowerRange(this.dimValuePrc);
      }
    });
    this.doorLockEmmiter.subscribe((val) => {
     this.onSwitch(true); 
    })
  }

  addScale (event) {
    (event.target as HTMLElement).closest('app-device').classList.add('scaleEl')
    
  }

  removeScale (event) {
    (event.target as HTMLElement).closest('app-device').classList.remove('scaleEl')
  }

  ngAfterViewInit() {    
    if (_.has(this.controls, "dimmer") && _.has(this.controls.dimmer, "cmd")) {
       this.powerange = this.powerangeInput ? new Powerange(this.powerangeInput.nativeElement, { decimal: false, hideRange: true }) : null;
      this.setPowerRange(this.dimValuePrc);
      let rangeBarEl = (this.powerangeInput.nativeElement as HTMLElement).closest(".powerangeroot").querySelector(".range-handle");
      if (this.isTv) {
        rangeBarEl.classList.add("focusable");
        (rangeBarEl as HTMLElement).tabIndex = -1;
        setTimeout(() => {
          rangeBarEl.addEventListener("focus", () => {
            console.log("focused");
            this.appInit.SN.disable("focusEl");
          });
          rangeBarEl.addEventListener("keydown", (e: KeyboardEvent) => {
            console.log("keydiwn");
            console.log(this.dimValuePrc);
            
            if (this.dimValuePrc >= 0 && this.dimValuePrc <= 95 && e.key === "ArrowRight") {
                this.onInputchangePercent(this.dimValuePrc + 5);
                this.setPowerRange(this.dimValuePrc);
            }  else if (this.dimValuePrc >= 5 && this.dimValuePrc <= 100 && e.key === "ArrowLeft") {
              if (this.dimValuePrc >= 5) {
                this.onInputchangePercent(this.dimValuePrc - 5);
                this.setPowerRange(this.dimValuePrc);
              }
            }
            if (e.key === "ArrowDown" || e.key === "ArrowUp") {
              this.appInit.SN.disable("handleBar");
              this.appInit.SN.enable("focusEl");
            }
          });
        }, 200);
      }
    }

    if (this.appInit.isTv) {
      setTimeout(() => {
        this.appInit.SN.focus("focusEl");
      }, 1000);
    }
    if (this.appInit.isTv) {
      setTimeout(() => {
        this.appInit.SN.focus("powerbar");
      }, 1000);
    }
  }

  private controlHasFeedback (control:Control, interfaceName: string):boolean {
    return _.has(control, interfaceName) && control[interfaceName].hasFeedback;
  }

  ngAfterContentInit() {
    this.commands = this.knxService.translateKnxCmds(this.deviceProperties.commands);
    this.initInterfaces();
    
  }

  setOpacity(perc: number) {
    if (perc === 0) {
      return 1;
    } else if (perc < 21) {
      return 0.2;
    }
    return perc / 100;
  }

  onSwitch(fromEmmiter:boolean = false) {
    if ( !_.has(this.controls, "binarySwitch") && !_.has(this.controls.dimmer, "cmd"))  {
      return;
    }
    let deviceDelay = !_.isNull(this.deviceProperties.delay) ? this.deviceProperties.delay :0;
    this.dimmerReqValue = !this.binaryStatus ? 100 : 0;
    this.responseTimeout = setTimeout(() => {
      this.responseFailedEmmiter.emit({status: false, hasFeedback: this.controls.binarySwitch.hasFeedback});
    }, +deviceDelay*1000);    
    this.knxService.sendCmd(!this.binaryStatus, this.binarySwitch, +this.deviceProperties.id, this.roomAreaId);
    if (!this.controls.binarySwitch.hasFeedback) {
      this.binaryStatus = !this.binaryStatus;
      this.setPowerRange(+this.binaryStatus * 100);
      this.opacity = this.setOpacity(+this.binaryStatus * 100);
      this.dimValuePrc = +this.binaryStatus * 100;
    }
    if (this.controls.binarySwitch.cmd.auto_off > 0 && !fromEmmiter) {
      setTimeout(() => {
        this.doorLockEmmiter.emit(this.controls.binarySwitch.cmd as KnxInterface);
      },this.controls.binarySwitch.cmd.auto_off*1000)
    }
  }

  onInputchangePercent(jsRangeValue: any) {
    let deviceDelay = !_.isNull(this.deviceProperties.delay) ? this.deviceProperties.delay :0;
    let percent = (jsRangeValue as HTMLInputElement).value;
    if (this.isTv) {
      percent = jsRangeValue;
    }    
    let reverse = !_.isNull(this.controls.dimmer.cmd.reverse) ?  this.controls.dimmer.cmd.reverse: 0;
    this.dimValuePrc = +percent;
    clearTimeout(this.powerangeTimeout);
    this.powerangeTimeout = setTimeout(() => {
      this.dimmerReqValue = +percent;
      this.responseTimeout = setTimeout(() => {
        this.responseFailedEmmiter.emit({status:false, hasFeedback: this.controls.dimmer.hasFeedback});
      }, +deviceDelay*1000); // New dimmer delays over 1 second for response
      this.knxService.sendCmd(+percent, this.dimmer, +this.deviceProperties.id, this.roomAreaId, reverse);
      if (!this.controls.dimmer.hasFeedback) {
        this.binaryStatus = true;
        this.opacity = this.setOpacity(+percent)
      }
    }, 600);
  }

  private setPowerRange(perc: number) {
    if (this.powerange !== null) {
      const powerangeRoot = (this.powerangeInput.nativeElement as HTMLInputElement).closest(".powerangeroot");
      const rangeBarWidth = (powerangeRoot.querySelector("span.range-bar") as HTMLElement).clientWidth;
      const rangeHanleWidth = (powerangeRoot.querySelector("span.range-handle") as HTMLElement).clientWidth;
      (powerangeRoot.querySelector("span.range-handle") as HTMLElement).style.left = ((rangeBarWidth - rangeHanleWidth) * perc) / 100 + "px";
      (powerangeRoot.querySelector("span.range-quantity") as HTMLElement).style.width = ((rangeBarWidth - rangeHanleWidth) * perc) / 100 + "px";
    }
  }

  trackBy(index: number, name): number {
    return name.id;
  }

  private initInterfaces() {  
    let deviceInterfaces = this.knxService.setDimmerInterface(this.commands, this.deviceProperties.router.address);
    Object.keys(deviceInterfaces).forEach((el) => {
      this[el] = deviceInterfaces[el];
    });
    this.controls = this.knxService.assignInterfacesToControls(this.interfaces);
  }

  private selectLocalSvg(imageUrl: string): void {
    const iconsPath: string = "/assets/icons/";
    const regex = /[a-z_]+.svg/gm;
    const str = imageUrl;
    let m: any;
    let icon;

    while ((m = regex.exec(str)) !== null) {
      if (m.index === regex.lastIndex) {
        regex.lastIndex++;
      }

      this.checkImageExists(iconsPath + m[0]);
      return;
    }
    this.icon = "/assets/icons/default.svg";
  }

  private checkImageExists(filename: string) {
   this.IconSubscription =  this.http
      .get(filename)
      .pipe(
        take(1),
        catchError((err) => {
          if (err.status === 200) {
            this.icon = filename;
          } else {
            this.icon = "/assets/icons/default.svg";
          }
          return of(err);
        })
      )
      .subscribe();
  }

  ngOnDestroy() {
    if (this.DeviceState) {
      this.DeviceState.unsubscribe();
    }
    if (this.IconSubscription) {
      this.IconSubscription.unsubscribe();
    }
  }

  constructor(private knxService: KNXService, private store: Store<fromApp.AppState>, private appInit: AppInit, private http: HttpClient) {}
}
