import { Action } from "@ngrx/store";
import { RoomArea } from "../../models/room.model";



export const FETCH_ROOM_AREAS = "[Room Areas] Fetch";
export const SET_ROOM_AREAS = "[Room Areas] Set";

export class FetchRoomAreas implements Action {
    readonly type = FETCH_ROOM_AREAS;
}

export class SetRoomAreas implements Action {
    readonly type = SET_ROOM_AREAS;

    constructor(public payload: RoomArea[]) {}
}

export type RoomsListActions = FetchRoomAreas | SetRoomAreas;