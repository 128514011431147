import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot, UrlSegment } from "@angular/router";
import { Store } from "@ngrx/store";
import { forkJoin, Observable, of } from "rxjs";
import { filter, map, mergeMap, switchMap, take, tap, withLatestFrom } from "rxjs/operators";
import * as fromApp from "../store/app.reducer";
import * as RoomActions from "../room/store/room.actions";
import { Actions, ofType } from "@ngrx/effects";
import * as RoomListActions from "../rooms-list/store/rooms-list.actions";
import * as Helpers from "src/app/shared/helpers";
import { AppInit } from "../services/init/app-init.service";

@Injectable({
  providedIn: "root",
})
export class RoomResolver implements Resolve<any> {
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
    
    
    let roomAreaId = +(route.url as UrlSegment[]).slice(-1).shift().path;

    if (this.appInit.roomAreaId !== null) {
      roomAreaId = this.appInit.roomAreaId
    }
    
    return this.store.select("roomDevices").pipe(
      take(1),
      withLatestFrom(this.store.select("roomAreas")),
      mergeMap(([devices, roomAreas]) => {
        
        if (Helpers.isEmptyObj(devices) && Helpers.isEmptyObj(roomAreas)) {
          
          this.store.dispatch(new RoomActions.RoomGetDevices({ id: roomAreaId }));
          this.store.dispatch(new RoomListActions.FetchRoomAreas());
          const setDevices$ = this.actions$.pipe(
              filter((action) => action.type === RoomActions.SET_DEVICES),
            take(1)
          );
          const setRoomAreas$ = this.actions$.pipe(
              filter((action) => action.type === RoomListActions.SET_ROOM_AREAS),
            take(1)
          );
            
          return forkJoin({
            setDevices: setDevices$,
            setRoomAreas: setRoomAreas$,
          })
        } else if (devices[roomAreaId] === undefined) {
          this.store.dispatch(new RoomActions.RoomGetDevices({ id: roomAreaId }));
          return this.actions$.pipe((ofType(RoomActions.SET_DEVICES), take(1)));
        } else {
          return of(devices);
        }
      })
    );
  }

  constructor(private store: Store<fromApp.AppState>, private actions$: Actions, private appInit: AppInit) {}
}
