import { ActionReducerMap } from '@ngrx/store';
import * as fromRoomsList from '../rooms-list/store/rooms-list.reducer';
import * as fromAuth from '../services/auth/store/auth.reducer';
import * as fromAppInit from "../services/init/store/appinit.reducer";
import * as fromRoom from "../room/store/room.reducer";
import * as fromMiddlewareAuth from "../services/knxmiddleware/store/knxmiddleware.reducer";


export interface AppState {
    roomAreas: fromRoomsList.State
    auth: fromAuth.State
    appInit: fromAppInit.State
    roomDevices: fromRoom.State
    middleware: fromMiddlewareAuth.State
}

export const appReducer: ActionReducerMap<AppState> = {
    roomAreas: fromRoomsList.RoomsListReducer,
    auth: fromAuth.AuthReducer,
    appInit: fromAppInit.AppInitReducer,
    roomDevices: fromRoom.RoomReducer,
    middleware: fromMiddlewareAuth.MiddlewareReducer

}