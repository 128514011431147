import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Actions, Effect, ofType } from "@ngrx/effects";
import { Store } from "@ngrx/store";
import * as RoomActions from "../store/room.actions";
import * as fromApp from "../../store/app.reducer";
import * as AppInitActions from "../../services/init/store/appinit.actions";
import { map, mergeMap, withLatestFrom } from "rxjs/operators";
import { DevicesRes } from "../../models/devicesRes";
import { forkJoin, of } from "rxjs";
import * as enviroment from "src/environments/environment";
import { InterfacesSetupService } from "src/app/services/interfaces.setup.service";
import { KNXService } from "src/app/services/knx.service";

@Injectable()
export class RoomEffects {
  @Effect()
  roomGetDevices = this.actions$.pipe(
    ofType(RoomActions.GET_DEVICES),
    withLatestFrom(this.store.select("appInit")),
    mergeMap(([action, appInit]) => {
      const roomID = (action as any).payload.id;
      
      this.store.dispatch(new AppInitActions.AppLoading());

      const body = new HttpParams().set("areaId", "" + roomID);

      return forkJoin({
        devices: this.http.post<DevicesRes>(enviroment.ocgEnv.domain + enviroment.ocgEnv.roomDevicesEp + appInit.roomId, body),
        roomSlug: of(roomID),
      });
    }),
    map((mixedData: { devices: DevicesRes; roomSlug: string }) => {      
      if (mixedData.devices.devices === undefined || mixedData.devices.devices.length === 0) {
        return new RoomActions.RoomSetDevices({ devices: null, roomSlug: mixedData.roomSlug });
      }      
      let newDevices;      
      newDevices = this.interfacesSetupService.setupInterface(mixedData.devices.devices);
      newDevices = newDevices.map((el) => { // TODO: Propably to be removed after state properties set
        return { ...el, dimmingStatus: null, binaryStatus: null, inView: true };
      });
      
      this.store.dispatch(new AppInitActions.AppLoaded());
      return new RoomActions.RoomSetDevices({ devices: newDevices, roomSlug: mixedData.roomSlug });
    })
  );

  constructor(private actions$: Actions, private http: HttpClient, private store: Store<fromApp.AppState>, private interfacesSetupService: InterfacesSetupService, private knxService: KNXService) {}
}
