// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  roomId: 3,
};

export const ocgEnv = {
  rootDomain: "https://welcome69254.ocg360.com/",
  domain: "https://welcome69254.ocg360.com/web/index.php?r=",
  roomAreasEp: "rooms/rooms/room-areas&id=",
  roomDevicesEp: "rooms/rooms/room-devices&id=",
  pass: "nF8_hT#!bRw7_Ak6",
  controlAuthenticateEp: "rooms/rooms/room-verify&id="
}

export const middlewareEnv = {
  socketUrl: "http://192.168.128.47:8081/",
  domainV1: "http://192.168.128.47:8081/api/v1/",
  authEp: "authenticate",
  switch: "middleware-knx-switch",
  dimmer: "middleware-knx-dimming",
  status: "middleware-knx-status"
}

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
