<div class="px-3">
    <div class="card">
        <div class="card-body">
          <h1 class="card-title">Unauthorized Access</h1>
          <h6 class="card-subtitle mb-2 text-muted">For your room's safety, your session has expired!<br><strong style="font-size: 1.2rem;">Please scan Barcode</strong> again to control your Smart Room.</h6>
          <p><strong>&copy;2022 Powered by OCG360</strong></p>
          <pre><span *ngIf="roomHash !== null">RoomHash: {{roomHash}}</span><br><span *ngIf="roomId !== null">RoomID: {{roomId}}</span><br><span *ngIf="roomUrls.length > 0">URL: {{this.roomUrls.join(" | ")}}</span><br><span *ngIf="canControl !== null">OCG Validation Status: {{canControl}}</span></pre>
        </div>
      </div>
</div>
