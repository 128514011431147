import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable, OnDestroy } from "@angular/core";
import { ActivatedRoute, ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from "@angular/router";
import { Store } from "@ngrx/store";
import { forkJoin, interval, Observable, of, Subscription } from "rxjs";
import { map, mergeMap, skipWhile, take, tap } from "rxjs/operators";
import * as fromApp from "../store/app.reducer";
import * as AppInitActions from "../services/init/store/appinit.actions";
import * as enviroment from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class AppGuard implements CanActivate, OnDestroy {
  private storeSub: Subscription;
  private canControl: boolean;
  canActivate(route: ActivatedRouteSnapshot, router: RouterStateSnapshot): Observable<boolean> | Observable<UrlTree> | Promise<boolean> {
    let appInitState;
    this.store
      .select("appInit")
      .pipe(take(1))
      .subscribe((resp) => {
        appInitState = resp;
      });

    const body = new HttpParams().set("hash", "" + (appInitState as any).roomHash);
    const roomId = (appInitState as any).roomId;
    return forkJoin({
      ocgResponse: this.http.post(enviroment.ocgEnv.domain + enviroment.ocgEnv.controlAuthenticateEp + roomId, body),
    }).pipe(
      map((resp) => {
        if ((resp.ocgResponse as any).match === true) {
          this.store.dispatch(new AppInitActions.AllowRoomControl());
          return true;
        }
        this.router.navigate(["/", "error"]);
      })
    );
  }
  ngOnDestroy() {
    if (this.storeSub) {
      this.storeSub.unsubscribe();
    }
  }

  constructor(private router: Router, private store: Store<fromApp.AppState>, private http: HttpClient) {}
}
