import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { Store } from "@ngrx/store";
import * as fromApp from "../store/app.reducer";
import * as AuthActions from "../services/auth/store/auth.actions";
import { Router } from "@angular/router";

@Component({
  selector: "app-login",
  templateUrl: "login.component.html",
  styleUrls: ["login.component.css"],
})
export class LoginComponent implements OnInit {
  @Input() username: string;
  @Input() password: string;
  loginMessage: string;

  ngOnInit() {}

  authenticate(username: HTMLInputElement, pass: HTMLInputElement) {
    this.store.dispatch(new AuthActions.LoginStart({ email: username.value, password: pass.value }));
  }

  constructor(private store: Store<fromApp.AppState>, private router: Router) {}
}
