import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from "@angular/router";
import { Store } from "@ngrx/store";
import * as fromApp from "../store/app.reducer";
import * as RoomsListActions from "./store/rooms-list.actions";
import { map, switchMap, take } from "rxjs/operators";
import { Actions, ofType } from "@ngrx/effects";
import { of } from "rxjs";
import * as Helpers from "src/app/shared/helpers";

@Injectable({
    providedIn: "root"
})
export class RoomsListResolver implements Resolve<any> {

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
       
        return this.store.select("roomAreas").pipe(
            take(1),
            switchMap(roomAreas => {             
                if (Helpers.isEmptyObj(roomAreas)) {                   
                    this.store.dispatch(new RoomsListActions.FetchRoomAreas());
                    return this.actions$.pipe(
                        ofType(RoomsListActions.SET_ROOM_AREAS),
                        take(1)
                    );
                } else {
                    return of(roomAreas);
                }
            })
        )
    }

    constructor(private store: Store<fromApp.AppState>, private actions$: Actions) {}
}