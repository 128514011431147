import { Action } from "@ngrx/store";

export const SET_ROOM_ID = "[AppInit] Set Room Id";
export const APP_LOADING = "[AppInit] App Loading";
export const APP_LOADED = "[AppInit] App Loaded";
export const SET_ROOM_HASH = "[AppInit] Set Room Hash";
export const SWITCH_THEME = "[AppInit] Switch Theme";
export const CHECK_R00M_CONTROL = "[AppInit] Check Room Control";
export const ALLOW_R00M_CONTROL = "[AppInit] Allow Room Control";
export const INCREMENT_INTERFACES_COUNT = "[AppInit] Increment Interfaces Count";
export const DECREMENT_INTERFACES_COUNT = "[AppInit] Decrement Interfaces Count";
export const SET_CURRENT_URL = "[AppInit] Set Current Url";
export const NAVIGATE_ROOM_AREAS = "[AppInit] Navigate Room Areas";
export const UPDATE_LAST_INTERACTION_TIMESTAMP = "[AppInit] Update Last Interaction Timestamp";

export class SetRoomId implements Action {
  readonly type = SET_ROOM_ID;

  constructor(public payload: { roomId: number }) {}
}

export class SetRoomHash implements Action {
  readonly type = SET_ROOM_HASH;

  constructor(public payload: { roomId: number; hash: string, roomAreaId?:number }) {}
}

export class AppLoading implements Action {
  readonly type = APP_LOADING;
}

export class AppLoaded implements Action {
  readonly type = APP_LOADED;
}

export class ChangeTheme implements Action {
  readonly type = SWITCH_THEME;
}

export class IncrementInterfacesInitCount implements Action {
  readonly type = INCREMENT_INTERFACES_COUNT;
}

export class DecrementInterfacesInitCount implements Action {
  readonly type = DECREMENT_INTERFACES_COUNT;
}

export class CheckRoomControl implements Action {
  readonly type = CHECK_R00M_CONTROL;
}

export class AllowRoomControl implements Action {
  readonly type = ALLOW_R00M_CONTROL;
}

export class NavigateRoomAreas implements Action {
  readonly type = NAVIGATE_ROOM_AREAS;
}

export class UpdateLastInteractionTimestamp implements Action {
  readonly type = UPDATE_LAST_INTERACTION_TIMESTAMP;

  constructor (public payload:number) {}
}

export type AppInitActions =
  | SetRoomId
  | SetRoomHash
  | AppLoading
  | AppLoaded
  | ChangeTheme
  | IncrementInterfacesInitCount
  | DecrementInterfacesInitCount
  | CheckRoomControl
  | AllowRoomControl
  | NavigateRoomAreas
  | UpdateLastInteractionTimestamp;
