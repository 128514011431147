import { AfterViewInit, Component, OnDestroy, OnInit, Pipe, PipeTransform, ViewChild, ViewEncapsulation } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { Store } from "@ngrx/store";
import { Observable, of, Subscription } from "rxjs";
import { mergeMap, take, withLatestFrom } from "rxjs/operators";
import { Device } from "../models/device.model";
import { RoomArea } from "../models/room.model";
import { SwitchService } from "../services/switching.service";
import * as fromApp from "../store/app.reducer";
import * as AppInitActions from "../services/init/store/appinit.actions";
import * as RoomActions from "./store/room.actions";
import { AppInit } from "../services/init/app-init.service";
import * as _ from "lodash";
import { SplideComponent } from "../splide/splide.component";
declare let Powerange: any;

interface RoomData {
  full: Device[];
  half: Device[];
}

@Pipe({
  name: "fill",
})
export class FillPipe implements PipeTransform {
  transform(value) {
    return new Array(value).fill(1);
  }
}
@Component({
  selector: "app-room",
  templateUrl: "room.component.html",
  styleUrls: ["room.component.css"],
  encapsulation: ViewEncapsulation.None,
})
export class RoomComponent implements OnInit, OnDestroy {
  @ViewChild('ocgSlide') ocgSlide:SplideComponent;
  ocgImage: string = "";
  private storeSub: Subscription;
  private themeSub: Subscription;
  private roomPath: string;
  private initPoweranges: boolean = false;
  private elements: any = null;
  isTv: boolean;
  halfCols: Observable<number> = of(0);
  roomData: RoomData;
  roomDatanew: Observable<any>
  filteredRoomData: RoomData;
  roomAreaTitle: string;
  theme: string;
  roomAreaId: number;
  deviceTypes: string[];
  private selectedFilter:string;
  inViewSub: Subscription;
  isActive: number = 0;

  constructor(private switchingService: SwitchService, private route: ActivatedRoute, private store: Store<fromApp.AppState>, private appInit: AppInit) {}

  ngOnInit(): void {    
    this.isTv = this.appInit.isTv;       
    this.ocgImage = this.isTv ? "https://cf.bstatic.com/xdata/images/hotel/max1024x768/233382725.jpg?k=55f3fe43b35b49abc45d39aee2a221e320ec60c9f4f235ee211eedf1f6747366&o=&hp=1" : "";
    this.storeSub = this.store
      .select("roomDevices")
      .pipe(
        withLatestFrom(this.store.select("appInit")),
        mergeMap(([devices, store]) => {
          let slug: string = this.route.snapshot.params.slug;
          if (store.roomAreaId !== null && store.roomAreaId !== undefined) {
            slug = "" + store.roomAreaId;
          }

          this.roomData = this.restructureRoomData(devices[slug]);
          this.filteredRoomData = {...this.roomData};
          if (!_.isUndefined(this.selectedFilter)) {
            this.selectDeviceType(this.selectedFilter, 0);
          }
          this.deviceTypes = this.getDeviceTypes(devices[slug]);

          // this.halfCols = this.roomData ?  of( Math.trunc((this.roomData.half.length as number)  / 2  ) + 1 ): of(0);
          this.roomPath = slug;
          return of({
            devices: devices[slug],
            slug: slug,
          });
        }),
        withLatestFrom(this.store.select("roomAreas")),
        mergeMap(([devicesRes, roomAreasRes]) => {

          let currentRoomArea = this.findRoomAreaWithId(roomAreasRes, +this.roomPath);

          let roomAreas = {};
          if ((roomAreasRes as Object).hasOwnProperty(+this.roomPath)) {
            const roomId = +this.roomPath;
            roomAreas = (roomAreasRes as any).roomId;
          }

          return of({
            devices: devicesRes.devices,
            roomArea: currentRoomArea,
            roomAreaId: +this.roomPath,
          });
        })
      )
      .subscribe((response) => {

        if (response.roomArea === undefined) {
          return;
        }
        this.roomAreaId = +response.roomAreaId;
        this.roomAreaTitle = response.roomArea.name;
      });
    this.themeSub = this.store.select("appInit").subscribe((appInit) => {
      this.theme = appInit.theme;
    });
  }

  switchTheme() {
    this.store.dispatch(new AppInitActions.ChangeTheme());
  }

  trackBy(i) {
    return i;
  }

  focusRootSplide() {
    (document.querySelector('splide .splide') as HTMLElement).focus();
  }

  private restructureRoomData(roomData: Device[]): RoomData {
    if (this.isIterable(roomData)) {
      const devicesArray = [...roomData];
      let restructuredData: RoomData = { full: [], half: [] };
      restructuredData.full = devicesArray.filter((deviceObj: Device) => deviceObj.width === 2);
      let halfDevices = devicesArray.filter((deviceObj: Device) => deviceObj.width === 1);
      restructuredData.half = halfDevices;      
      return restructuredData;
    }
  }

  selectDeviceType(deviceType, key: number) {
    this.isActive = key;
    this.store.dispatch(new RoomActions.RoomSetDeviceView({roomId: ''+this.roomAreaId, deviceTypeView: deviceType }));
  }

  private getDeviceTypes (roomData: Device[]): string[] {
    let types = ["All"];
   _.forEach(roomData, (element) => {
     if (!_.includes(types, element.type)) {
       types.push(element.type);
     }
   })  
    return types;
  }

  private isIterable(obj) {
    if (obj == null) {
      return false;
    }
    return typeof obj[Symbol.iterator] === "function";
  }

  ngOnDestroy() {
    if (this.storeSub) {
      this.storeSub.unsubscribe();
    }
    if (this.themeSub) {
      this.themeSub.unsubscribe();
    }
  }

  private findRoomAreaWithId(roomAreasRes: any, id: number): RoomArea {
    let foundEl = [];
    let roomAreaArray = Object.values(roomAreasRes);
    foundEl = roomAreaArray.filter((el, index) => (el as any).id == id);
    return foundEl[0];
  }
}
