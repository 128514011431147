import { Action } from "@ngrx/store";
import { Device } from "../../models/device.model";


export const GET_DEVICES = "[Room] Get Devices";
export const SET_DEVICES = "[Room] Set Devices";
export const SET_DEVICES_VIEW = "[Room] Set Devices View";
export const SET_NULL_FB = "[Room] Set Null FB";


export class RoomGetDevices implements Action {
    readonly type = GET_DEVICES;
    constructor(public payload: {id: number}){}
}

export class RoomSetDevices implements Action {
    readonly type = SET_DEVICES;

    constructor (public payload: {devices: Device[], roomSlug: string}){}
}

export class RoomSetNullFb implements Action {
    readonly type = SET_NULL_FB;

    constructor (public payload: {type:string, address: string, roomAreaId: number}){}
}

export class RoomSetDeviceView implements Action {
    readonly type = SET_DEVICES_VIEW;

    constructor (public payload: {roomId: string, deviceTypeView: string} ) {}
}

export type RoomActions = RoomGetDevices | RoomSetDevices | RoomSetDeviceView | RoomSetNullFb;