import { AfterViewInit, Component, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Store } from "@ngrx/store";
import * as AuthActions from "./services/auth/store/auth.actions";
import * as fromApp from "./store/app.reducer";
import { AppInit } from "./services/init/app-init.service";
import { Subscription } from "rxjs";
import * as AppInitActions from "./services/init/store/appinit.actions";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.css"],
})
export class AppComponent implements OnInit, OnDestroy {
  isLoading: boolean = false;
  private storeSub: Subscription;
  private allowControl: false;

  constructor(private router: Router, private store: Store<fromApp.AppState>, private init: AppInit, private route: ActivatedRoute) {}

  ngOnInit() {
    this.init.runInit();
    this.store.dispatch(new AuthActions.AuthLogin());
    this.storeSub = this.store.select("appInit").subscribe((appInit) => {
      this.isLoading = appInit.appLoading > 0;
    });
  }
  ngOnDestroy() {
    if (this.storeSub) {
      this.storeSub.unsubscribe();
    }
  }
}
