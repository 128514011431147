import { Injectable } from "@angular/core";
import { io } from "socket.io-client";
import * as enviroment from "src/environments/environment";
import { SocketsRes } from "src/app/models/socketsRes.model";
import { SocketFb } from "src/app/models/SocketFb.model";
import { KNXService } from "./knx.service";
import { Store } from "@ngrx/store";
import * as fromApp from "src/app/store/app.reducer";
import * as Helpers from "src/app/shared/helpers";
import * as MiddlewareActions from "src/app/services/knxmiddleware/store/knxmiddleware.actions";
import { Subscription } from "rxjs";
import * as _ from "lodash";
import { take } from "rxjs/operators";
import { element } from "protractor";

@Injectable({
  providedIn: "root",
})
export class SocketService {
  private socket: any;
  private commands: SocketFb[];
  private interfacesInit: boolean = false;
  private readMode: string = "GroupValue_Response";
  private appInitSub: Subscription;

  public initSocket(): void {
    try {
      this.socket = io(enviroment.middlewareEnv.socketUrl);
      this.socket.on("connect", () => {
        console.log("connected");
        this.socket.on("knxevents", (val: SocketsRes) => {
          if (this.commands === undefined) {
            return;
          }
          
          let currentCommand;
          if (this.readMode === "GroupValue_Response") {
            currentCommand = { ...this.commands.filter((command) => command.address === val.destination && val.event === "GroupValue_Response") };
          } else {
            currentCommand = { ...this.commands.filter((command) => command.address === val.destination && val.event === command.feedback) };
          }
        if (_.isEmpty(currentCommand)) {
          return;
        }
        currentCommand = currentCommand[0]; 
        console.log(currentCommand);
        let isReverse = false;
        let dpst:string;
        this.store.select('roomDevices').pipe(
          take(1),
        ).subscribe((res) => {
          const areaKey = Object.keys(res)[0];
          console.log(res[areaKey]);
          const allCommands = []
          _.forEach(res[areaKey], (element) => {
            _.forEach(element.commands, (command) => {
              allCommands.push(command);
            })
          })   
        let foundCommand = _.filter(allCommands, (el) => {
          return el.address === currentCommand.address
        }).pop();
        console.log(foundCommand);
        
         if (foundCommand.reverse === 1 && foundCommand.type === "fb") {
           isReverse = true;
           dpst = foundCommand.dpst;
         }
                 
        })
        let value = isReverse ? this.knxService.reverseValue(dpst, val.hex, false) : val.hex;
        
       // currentCommand.dpst = this.knxService.translateDPST(currentCommand.dpst);
          this.store.dispatch(new MiddlewareActions.SetDeviceStatus({
                fb: currentCommand.address,
                value: +value,
                deviceId: currentCommand.deviceId,
                roomAreaId: currentCommand.roomAreaID,
                dpst: currentCommand.dpst,
          }));
        });
      });
    } catch {
      (err) => {
        console.log(err);
      };
    }
  }

  public initSocketCommands() {
    this.store.select("roomDevices").subscribe((devices) => {
      if (!Helpers.isEmptyObj(devices)) {
        let commandsArray: SocketFb[] = [];
        for (const [roomAreaId, roomAreaDevices] of Object.entries(devices)) {
          if (roomAreaDevices instanceof Array) {
            roomAreaDevices.forEach((el) => {
              if (el.commands.length > 0) {
                el.commands.forEach((command) => {
                  if (command.type === "fb") {
                    commandsArray.push({
                      deviceId: el.id,
                      roomAreaID: el.area.id,
                      dpst: command.dpst,
                      address: command.address,
                      routerIp: el.router.address,
                      feedback: command.feedback === "write" || command.feedback === "" ? "GroupValue_Write" : "GroupValue_Response",
                    });
                  }
                });
              }
            });
          }
        }
        this.commands = commandsArray;
      }
      this.checkInterfacesOnInit();
    });
  }

  public checkInterfacesOnInit() {
    if (this.commands !== undefined && this.commands.length > 0) {
      if (this.interfacesInit) {
        return;
      }
      this.commands.forEach((command) => {
        setTimeout(() => {
          let formatedCmd = { ...command, dpst: this.knxService.translateDPST(command.dpst) };          
          this.store.dispatch(
            new MiddlewareActions.GetInterfaceStatus({ ip: formatedCmd.routerIp, status: formatedCmd.address, dpt: formatedCmd.dpst, roomAreaId: formatedCmd.roomAreaID })
          );
        });
      });
      this.interfacesInit = true;
    }
  }

  constructor(private knxService: KNXService, private store: Store<fromApp.AppState>) {
    this.appInitSub = this.store.select("appInit").subscribe((appInit) => {
      if (appInit.interfacesCount === 0) {
        setTimeout(() => {
          this.readMode = "GroupValue_Write";
        }, 200);
      }
    });
  }
}
