import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Actions, Effect, ofType } from "@ngrx/effects";
import { Store } from "@ngrx/store";
import { catchError, map, mergeMap, withLatestFrom } from "rxjs/operators";
import { RoomAreasRes } from "../../models/roomsAreaRes.model";
import * as RoomsListActions from "../store/rooms-list.actions";
import * as fromApp from "../../store/app.reducer";
import * as AppInitActions from "../../services/init/store/appinit.actions";
import * as enviroment from "src/environments/environment";
import { of } from "rxjs";


@Injectable()
export class RoomsListEffects {

    @Effect()
    fetchRoomAreas = this.actions$.pipe(
        ofType(RoomsListActions.FETCH_ROOM_AREAS),
        withLatestFrom(this.store.select("appInit")),
        mergeMap(([action, appInit]) => {
            this.store.dispatch(new AppInitActions.AppLoading());
            let body = new HttpParams().set("pass", enviroment.ocgEnv.pass);
            // return of({});
            return this.http.post<RoomAreasRes>(
               enviroment.ocgEnv.domain +  enviroment.ocgEnv.roomAreasEp + appInit.roomId,
               body
            );
        }),
     
            catchError( (err) => {
                
               return of({})
                
            }),
        map((roomAreas : RoomAreasRes) => {
            
            this.store.dispatch(new AppInitActions.AppLoaded());
            return new RoomsListActions.SetRoomAreas(roomAreas.areas);
        })
        
    )


    constructor(private actions$: Actions, private http:HttpClient, private store: Store<fromApp.AppState>){}

}