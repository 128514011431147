import * as MiddlewareActions from "../store/knxmiddleware.actions";


export interface State {
    token: string,
    isAuth: boolean
}

export const initialState: State = {
    token: null,
    isAuth: null
}

export function MiddlewareReducer (state = initialState, action: MiddlewareActions.MiddlewareActions) {    
    switch (action.type) {
        case MiddlewareActions.MIDDLEWARE_AUTH_START:           

            return {
                ...state
            }
        case MiddlewareActions.MIDDLEWARE_AUTH_SUCCESS:
            return {
                ...state,
                token:action.payload.token,
                isAuth: true
            }
        case MiddlewareActions.MIDDLEWARE_AUTH_FAIL:
                return {
                    ...state,
                    token: null,
                    isAuth: false
                }       
        default:
            return state
    }
 }