import * as RoomsListActions from "../store/rooms-list.actions";
import { RoomArea } from "../../models/room.model";

export interface State{
    [index: number]: RoomArea
};

const initialState: State = {
   
};

export function RoomsListReducer(state: State = initialState, action: RoomsListActions.RoomsListActions) {
  switch (action.type) {
    case RoomsListActions.SET_ROOM_AREAS:
      return {
        ...state,
        ...action.payload
      };
    default:
      return state;
  }
}
