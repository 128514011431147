import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Actions, Effect, ofType } from "@ngrx/effects";
import { Store } from "@ngrx/store";
import { catchError, map, switchMap, tap } from "rxjs/operators";
import * as fromApp from "src/app/store/app.reducer";
import * as MiddlewareActions from "../store/knxmiddleware.actions";
import * as AppInitActions from "src/app/services/init/store/appinit.actions";
import * as RoomActions from "src/app/room/store/room.actions";
import * as enviroment from "src/environments/environment";
import { Observable, of } from "rxjs";
import * as KnxMiddlewareActions from "./knxmiddleware.actions";
import * as MiddlewareCmds from "src/app/models/middlewareCmd.model";

// const handleAuthentication = (username: string, token: string, expiresIn: number, ) => {

//     return new AuthAction.AuthenticateSuccess({ username: username, token: token, expirationTime: expirationDate , redirect: true});
//   };
//  const handleError = (errorRes: HttpErrorResponse) => {

//     let errorMessage = "An unknown error occurred!";
//     if (!errorRes.error.code || !errorRes.error.message) {
//       return of(new AuthAction.AuthenticateFail({message: errorMessage}));
//     }
//     console.log(errorRes);
//     return of(new AuthAction.AuthenticateFail({message: errorRes.error.message, status: errorRes.error.data.status}));
//   };

@Injectable()
export class MiddlewareEffects {
  @Effect()
  middleAuthStart = this.actions$.pipe(
    ofType(MiddlewareActions.MIDDLEWARE_AUTH_START),
    switchMap(() => {
      this.store.dispatch(new AppInitActions.AppLoading());

      return this.http.post<{ token: string }>(enviroment.middlewareEnv.domainV1 + enviroment.middlewareEnv.authEp, "").pipe(
        map((resp) => {
          this.store.dispatch(new AppInitActions.AppLoaded());
          return new MiddlewareActions.MiddlewareAuthSuccess({ token: resp.token });
        }),
        catchError((err) => {
          return of(new MiddlewareActions.MiddlewareAuthFail());
        })
      );
    })
  );

  @Effect()
  authFail = this.actions$.pipe(
    ofType(MiddlewareActions.MIDDLEWARE_AUTH_FAIL),
    map(() => {
      return new AppInitActions.AppLoaded();
    })
  );

  @Effect({ dispatch: false })
  sendKnxCommand = this.actions$.pipe(
    ofType(KnxMiddlewareActions.DEVICE_SEND_KNX_CMD),
    switchMap((actionData: KnxMiddlewareActions.SendKnxCommand) => {
      const actionPayload = actionData.payload;
      const knxMiddleware = enviroment.middlewareEnv.domainV1;
      let body: MiddlewareCmds.MiddlewareDimmingCmd | MiddlewareCmds.MiddlewareBinnaryCmd;
      let httpObs: Observable<any> = null;
      console.log(actionPayload.cmd.dpst);

      if ((actionPayload.cmd.dpst as string).includes("DPT5")) {
        body = {
          ip: actionPayload.cmd.router,
          device: actionPayload.cmd.address,
          dimming: actionPayload.value,
          dpt: actionPayload.cmd.dpst,
        };
        return (httpObs = this.http.post(knxMiddleware + enviroment.middlewareEnv.dimmer, body));
      } else if ((actionPayload.cmd.dpst as string).includes("DPT1")) {
        body = { ip: actionPayload.cmd.router, device: actionPayload.cmd.address, value: actionPayload.value };
        return (httpObs = this.http.post(knxMiddleware + enviroment.middlewareEnv.switch, body));
      }
    })
  );

  @Effect({ dispatch: false })
  readKnxStatus = this.actions$.pipe(
    ofType(KnxMiddlewareActions.GET_INTERFACE_STATUS),
    switchMap((actionData: KnxMiddlewareActions.GetInterfaceStatus) => {
      let body = { ip: actionData.payload.ip, status: actionData.payload.status, dpt: actionData.payload.dpt };
      this.store.dispatch(new AppInitActions.IncrementInterfacesInitCount());
      return of(
        this.http.post(enviroment.middlewareEnv.domainV1 + enviroment.middlewareEnv.status, body).subscribe(
          (res) => {
            this.store.dispatch(new AppInitActions.DecrementInterfacesInitCount());
          },
          (error) => {
            this.store.dispatch(new RoomActions.RoomSetNullFb({type: "fb", address: actionData.payload.status, roomAreaId: actionData.payload.roomAreaId}))
            this.store.dispatch(new AppInitActions.DecrementInterfacesInitCount());
          }
        )
      );
    })
  );

  constructor(private actions$: Actions, private http: HttpClient, private store: Store<fromApp.AppState>) {}
}
