import { Device } from "../../models/device.model";
import * as RoomActions from "../store/room.actions";
import * as MiddlewareActions from "src/app/services/knxmiddleware/store/knxmiddleware.actions";
import * as _ from "lodash";

export interface State {
  [areaSlug: string]: Device[];
}

const InitialState: State = {};

export function RoomReducer(state = InitialState, action: RoomActions.RoomActions | MiddlewareActions.MiddlewareActions) {
  let newDevices ;
  switch (action.type) {
    case RoomActions.SET_DEVICES:      
      return {
        ...state,
        [action.payload.roomSlug]: action.payload.devices,
      };
    case MiddlewareActions.DEVICE_SET_KNX_STATUS:
      let roomAreaId = action.payload.roomAreaId;
      if (_.isUndefined(roomAreaId)) {
        return {
          ...state
        };
      }
      let newState = { ...state };
      let newDevice = { ...newState[action.payload.roomAreaId].filter((el) => el.id === action.payload.deviceId)[0] };      
      let dpst: string = action.payload.dpst;      
      newDevice = {...newDevice, interfaces: {...newDevice.interfaces, [dpst]: {...newDevice.interfaces[dpst], currentValueRaw: action.payload.value }}}
      newDevices = state[action.payload.roomAreaId].map((element, index) => {
        if (element.id === action.payload.deviceId) {
          return newDevice;
        }
        return element;
      });
      
      return {
        ...newState,
        [roomAreaId]: newDevices,
      };
    case RoomActions.SET_DEVICES_VIEW:
      newDevices = _.map([...state[action.payload.roomId]], (element) => {
        let newElement = {...element};
        newElement.inView = action.payload.deviceTypeView === "All" ? true: element.type === action.payload.deviceTypeView;
        return newElement;
      })
      return {
        ...state,
        [action.payload.roomId]: newDevices
      };
    case RoomActions.SET_NULL_FB:
      let devicePositionInArray;
        let foundDevice = _.pickBy([...state[action.payload.roomAreaId]], (device, position) => {                  
          let foundInterface = _.filter(device.commands, (command) => {            
            return command.address === action.payload.address && command.type === "fb";
          })                   
          if (!_.isEmpty(foundInterface)) {                        
            devicePositionInArray = position;
            return true;
          }
        });
        let newfoundDevice = foundDevice[Object.keys(foundDevice)[0]];

        let mutatedDevice;
          _.forEach(newfoundDevice.interfaces, (value, key) => {
            if (!_.isNull(value) && !_.isNull(value.fb)) {
              if (value.fb.address === action.payload.address) {
                 mutatedDevice = {...newfoundDevice,
                   interfaces: {...newfoundDevice.interfaces,
                   [key]: {...newfoundDevice.interfaces[key],
                   fb: null
                   }
                   }
                 }
               }
            }
           })
           newDevices =  _.map([...state[action.payload.roomAreaId]], (element, key) => {
             if (key == devicePositionInArray) {
               return mutatedDevice
             } else {
               return element
             }
           })
           
           
        return {
          ...state,
          [action.payload.roomAreaId]: newDevices
        }

    default:
      return state;
  }
}
