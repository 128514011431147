import * as AppInitActions from "../store/appinit.actions";

export interface State {
  roomId: number;
  appLoading: number;
  roomHash: string;
  canControl: boolean;
  theme: string;
  interfacesCount: number;
  roomAreaId: number;
  lastInteractionTimestamp: number
}

export const InitialState: State = {
  roomId: null,
  appLoading: 0,
  roomHash: null,
  canControl: false,
  theme: "dark",
  interfacesCount: null,
  roomAreaId: null,
  lastInteractionTimestamp: + new Date()
};

export function AppInitReducer(state: State = InitialState, action: AppInitActions.AppInitActions) {
  let loadingNum = null;
  switch (action.type) {
    case AppInitActions.SET_ROOM_ID:
      return {
        ...state,
        roomId: +action.payload.roomId,
      };
    case AppInitActions.SET_ROOM_HASH:
           
      if (state.roomId === action.payload.roomId) {
        if (action.payload.roomAreaId !== null) {
        return {
          ...state,
          roomHash: action.payload.hash,
          roomAreaId: action.payload.roomAreaId
        };
      } else {
        return {
          ...state,
          roomHash: action.payload.hash,
        };
      }
      }
      return {
        ...state,
      };
    case AppInitActions.APP_LOADING:
      loadingNum = state.appLoading + 1;
      return {
        ...state,
        appLoading: loadingNum,
      };
    case AppInitActions.APP_LOADED:
      loadingNum = state.appLoading - 1;
      return {
        ...state,
        appLoading: loadingNum,
      };
    case AppInitActions.SWITCH_THEME:
      const newTheme = state.theme === "dark" ? "light" : "dark";
      return {
        ...state,
        theme: newTheme,
      };
    case AppInitActions.INCREMENT_INTERFACES_COUNT:
      return {
        ...state,
        interfacesCount: state.interfacesCount + 1,
      };
    case AppInitActions.DECREMENT_INTERFACES_COUNT:
      return {
        ...state,
        interfacesCount: state.interfacesCount - 1,
      };
    case AppInitActions.CHECK_R00M_CONTROL:
      return {
        ...state,
      };
    case AppInitActions.ALLOW_R00M_CONTROL:
      return {
        ...state,
        canControl: true,
      };
    case AppInitActions.UPDATE_LAST_INTERACTION_TIMESTAMP:
      return {
        ...state,
        lastInteractionTimestamp: action.payload
      }  
    default:
      return state;
  }
}
