import { HttpHandler,  HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { exhaustMap, map, take } from "rxjs/operators";
import * as fromApp from "../../store/app.reducer";
import * as enviroment from "src/environments/environment";

@Injectable()
export class AuthInterceptorService implements HttpInterceptor {
  intercept(req: HttpRequest<any>, next: HttpHandler) {
    return this.store.select("middleware").pipe(
      take(1),
      map((authState) => {
        return authState;
      }),
      exhaustMap((authState) => {        
        if (!authState) {
          return next.handle(req);
        }
        let modifiedReq;
        if (req.url.includes(enviroment.middlewareEnv.domainV1)) { 
          return next.handle(req);
        } else if (req.url.includes(enviroment.ocgEnv.domain)) {
        modifiedReq = req.clone({
          body: req.body.set("pass", enviroment.ocgEnv.pass),
        });
        return next.handle(modifiedReq);
      } else {
        return next.handle(req);
      }
      })
    );
  }

  constructor(private store: Store<fromApp.AppState>) {}
}
