import * as AuthActions from "../store/auth.actions"
import { User }from "../user.model";

export interface State {
    user: User,
    authError: string
}

export const initialState: State = {
    user: new User("test", "test", new Date()),
    authError: null,
}

export function AuthReducer (state = initialState, action: AuthActions.AuthActions) {
   switch (action.type) {
       case AuthActions.AUTHENTICATE_SUCCESS:
            const user = new User(action.payload.username, action.payload.token, action.payload.expirationTime);

            return {
                ...state,
                user
            }
        case AuthActions.LOGIN_START:
            
            return {
                ...state,
            }
        case AuthActions.AUTHENTICATE_FAIL:
            return {
                ...state,
                ...initialState,
                authError:action.payload.message
            }    
        default:
            return state   
   } 

}