import { HttpClient,  HttpErrorResponse,  HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Actions, Effect, ofType } from "@ngrx/effects";
import { catchError, map, switchMap, tap } from "rxjs/operators";
import * as AuthAction from "../store/auth.actions";
import {User} from "../user.model";
import {AuthResponseData, AuthService} from "../auth.service";
import { of } from "rxjs";
import { Router } from "@angular/router";
import { Store } from "@ngrx/store";
import * as fromApp from "../../../store/app.reducer";
import * as AppInitActions from "../../init/store/appinit.actions";


const handleAuthentication = (username: string, token: string, expiresIn: number, ) => {
    const expirationDate = new Date(new Date().getTime() + +expiresIn * 1000);
    const user = new User(username, token, expirationDate);
    localStorage.setItem('userData', JSON.stringify(user));
    return new AuthAction.AuthenticateSuccess({ username: username, token: token, expirationTime: expirationDate , redirect: true});
  };
 const handleError = (errorRes: HttpErrorResponse) => {

    let errorMessage = "An unknown error occurred!";
    if (!errorRes.error.code || !errorRes.error.message) {
      return of(new AuthAction.AuthenticateFail({message: errorMessage}));
    }
    return of(new AuthAction.AuthenticateFail({message: errorRes.error.message, status: errorRes.error.data.status}));
  };

@Injectable()
export class AuthEffects {
    @Effect()
    authSignIn = this.actions$.pipe(ofType(AuthAction.LOGIN_START),
        tap( () => {
          // this.store.dispatch(new AppInitActions.AppLoading())
        }
        ),
        switchMap((authData: AuthAction.LoginStart) => {
            return this.http.post<AuthResponseData>("http://knx.backend/wp-json/jwt-auth/v1/token", "",{
               params: new HttpParams().set("username", authData.payload.email).set("password", authData.payload.password)
            })
            .pipe(
               map(resData => {
                  this.auth.isValidUser = true;
                   this.auth.userEmail = resData.user_email;
                   this.store.dispatch(new AppInitActions.AppLoaded());
                   return handleAuthentication(resData.user_display_name, resData.token, resData.expiration_time )
               }),
               catchError((resData: HttpErrorResponse) => {
                this.store.dispatch(new AppInitActions.AppLoaded());
                 return handleError(resData);
               })
            )
        })
    )

    @Effect()

    autoLogin = this.actions$.pipe(
      ofType(AuthAction.AUTO_LOGIN), map(() => {        
        const userData : {
          username: string,
          _token: string,
          _tokenExpirationDate: string
        } = JSON.parse(localStorage.getItem("userData"));
        if (!userData) {
          return {type: "DUMMY"};
        }

        const loadedUser = new User(
          userData.username,
          userData._token,
          new Date(userData._tokenExpirationDate)
        );
        

        if (loadedUser.token) {  
          // this.user.next(loadedUser);
          // localStorage.removeItem("userData");
          const expirationDuration =
            new Date(userData._tokenExpirationDate).getTime() -
            new Date().getTime();
          // this.authService.setLogoutTimer(expirationDuration);
          return new AuthAction.AuthenticateSuccess({
            username: loadedUser.username,
            token: loadedUser.token,
            expirationTime: new Date (userData._tokenExpirationDate),
            redirect: false
          });
          // const expirationDuration =
          //   new Date(userData._tokenExpirationDate).getTime() -
          //   new Date().getTime();
          // this.autoLogout(expirationDuration);
        } else {
          return new AuthAction.AuthenticateFail({message:"failed"})
        }
        return {type: "DUMMY"};
      })
      )

    @Effect({dispatch: false})
    
    authLogout = this.actions$.pipe(
      ofType(AuthAction.LOGOUT),
      tap(() => {
        this.auth.clearLogoutTimer();
        localStorage.removeItem('userData');
        this.router.navigate(["/login"]);
      })
    )

    @Effect({dispatch: false})

    authSuccess = this.actions$.pipe(
      ofType(AuthAction.AUTHENTICATE_SUCCESS),
      map(authData => {
        return (authData as any).payload;
      }),
      tap((payload) => {
      if(payload.redirect) {
        this.router.navigate(["/", "roomAreas"]);
      }
               
      })
    )

    @Effect({dispatch: false})

    authFail = this.actions$.pipe(ofType(AuthAction.AUTHENTICATE_FAIL),
      tap(() => {
        this.router.navigate(["/","login"]);
      })
    )
    

    constructor (private actions$: Actions, private http: HttpClient, private auth: AuthService, private router: Router, private store: Store<fromApp.AppState>){}
}