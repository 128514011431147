import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { Store } from "@ngrx/store";
import * as _ from "lodash";
import { take } from "rxjs/operators";
import * as KnxMiddlewareActions from "src/app/services/knxmiddleware/store/knxmiddleware.actions";
import * as AppInitActions from "src/app/services/init/store/appinit.actions";
import * as fromApp from "src/app/store/app.reducer";
import { Control } from "../models/control.model";

interface KnxDeviceProperties {
  isDimmable: boolean;
}

export interface KnxCommand {
  type: string;
  dpst: string;
  address: string;
  router: string;
}

export interface KnxInterface {
  cmd?: { address: string; type: string; dpst: string; router: string; port: string, auto_off?: number, reverse?: number } | null;
  fb?: { address: string; type: string; dpst: string; router: string; port: string, reverse?:number } | null;
  currentValueRaw?: string;
  hasFeedback?: boolean
}
{
}

export interface OcgKnxCommands {
  id?: number;
  name?: string;
  type: string;
  address: string;
  dpst: string;
  interface?: string;
  feedback?: string;
  auto_off?: number;
  reverse?:number
}

@Injectable({
  providedIn: "root",
})
export class KNXService {
  sendCmd(dimValue: number | boolean, knxInterface: KnxInterface, deviceId: number, roomAreaId: number, reverse = 0) {
    
    
    console.log(knxInterface);
    const now = + new Date();
    this.store.select('appInit').pipe(take(1)).subscribe(
      appInit => {
        if (now - appInit.lastInteractionTimestamp > 9 * 60 *1000) {
          this.router.navigate(["/", "error"]);
          return;
        }
        this.store.dispatch(new AppInitActions.UpdateLastInteractionTimestamp(+new Date()))
      }
    )
    switch (true) {
      case _.includes(knxInterface.cmd.dpst, "DPT5") :
        dimValue = reverse === 0 ? dimValue: 100 - +dimValue;
        break;
        case _.includes(knxInterface.cmd.dpst, "DPT1") :
          dimValue = reverse === 0 ? dimValue: 1 - +dimValue;
          break;  
    }    
    let fb: string = knxInterface.fb ? knxInterface.fb.address : "";
    if (knxInterface.cmd) {
      console.log(
        "Send CMD of type " +
          knxInterface.cmd.dpst +
          " to KNX address " +
          knxInterface.cmd.address +
          " on IP router " +
          knxInterface.cmd.router +
          " with value of " +
          dimValue
      );
      // this.store.dispatch(new KnxMiddlewareActions.SendKnxCommand({cmd: {...knxInterface.cmd, type: "cmd"}, value: dimValue, fb:knxInterface.fb.address}));

      this.store.dispatch(
        new KnxMiddlewareActions.SendKnxCommand({ cmd: { ...knxInterface.cmd, type: "cmd" }, value: dimValue, fb: fb, deviceId, roomAreaId })
      );
    }
  }

  setDimmerInterface(knxCmds: OcgKnxCommands[], routerAddress: string): { dimmer: KnxInterface; binarySwitch: KnxInterface } {
    let dimmerInterface = { dimmer: {}, binarySwitch: {} };
    knxCmds.forEach((cmd) => {
      if (cmd.dpst.includes("DPT5")) {
        dimmerInterface.dimmer[cmd.type] = { address: cmd.address, dpst: cmd.dpst, router: routerAddress };
      } else if (cmd.dpst.includes("DPT1")) {
        dimmerInterface.binarySwitch[cmd.type] = { address: cmd.address, dpst: cmd.dpst, router: routerAddress };
      }
    });

    if (!dimmerInterface) {
      return null;
    }
    return dimmerInterface;
  }

  assignInterfacesToControls(interfaces: { "DPST-1-1": KnxInterface | null; "DPST-3-7": KnxInterface | null; "DPST-5-1": KnxInterface | null }) : Control {
    let control: Control = {
      dimmer: null,
      binarySwitch: null,
      increment: null
    }
    _.forOwn(interfaces, (knxInterface: KnxInterface, value:string) => {

       switch (true) {
         case _.includes(value, "DPST-1"):           
         if (!_.isNull(knxInterface) && !_.isNull(knxInterface.fb)) {
              control.binarySwitch = {...knxInterface, hasFeedback: true};
           } else {
            control.binarySwitch = {...knxInterface, hasFeedback: false};
           }
           break;
         case _.includes(value, "DPST-5"):
          if (!_.isNull(knxInterface) && !_.isNull(knxInterface.fb)) {
           control.dimmer = {...knxInterface, hasFeedback: true};
          } else {
            control.dimmer = {...knxInterface, hasFeedback: false};
          }
           break;
         case _.includes(value, "DPST-3"):           
          if (!_.isNull(knxInterface) && !_.isNull(knxInterface.fb)) {
           control.increment = {...knxInterface, hasFeedback: true};
          } else {
            control.increment =  {...knxInterface, hasFeedback: false};
          }
           break;    
       }
    })    
    return control;
    
  }

  translateKnxCmds(knxCmds: OcgKnxCommands[]) {
    let translatedCmds;
    translatedCmds = knxCmds.map((command) => {
      let newCmd = {
        ...command,
        dpst: this.translateDPST(command.dpst),
      };
      return newCmd;
    });
    return translatedCmds;
  }

  translateDPST(dpst: string): string {
    const regex = /^([a-zA-Z]+)\-(\d+)-(\d+)$/gm;
    let matches;
    matches = regex.exec(dpst);
    if (matches === null) {
      return "";
    }

    let result = dpst.replace(regex, `DPT$2.$3`);
    let resultArray = result.split(".");
    if ((resultArray.length = 2)) {
      resultArray[1] = this.pad(+resultArray[1], 3);
    }

    return resultArray.join(".");
  }

  convertValueByDpst (cmd:OcgKnxCommands, value:number, toRaw:boolean = true) {
    let result: number;
   switch (cmd.dpst) {
     case "DPST-1-1":
       result = value;
       break;
     case "DPST-5-1":
       if (toRaw) {
        result =  Math.round((value * 100) / 255);  
       } else {
         result = Math.round((value/100) * 255);
       }
   }
    
    return result;
  }

  reverseValue (dpst:string, value:number, base100 = true) {
    let result: number;
    let topValue;
    switch (dpst) {
      case "DPST-1-1":
        topValue = 1;
        result =  topValue - value;
        break;
      case "DPST-5-1":
        topValue = base100? 100: 255;
        result =  topValue - value;  
        break
      default:
        result = value;  
        }
     
     return result;
   }

  private pad(num, size) {
    num = num.toString();
    while (num.length < size) num = "0" + num;
    return num;
  }
  constructor(private http: HttpClient, private store: Store<fromApp.AppState>, private router: Router) {}
}
