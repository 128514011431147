import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Inject, Injectable, InjectionToken } from "@angular/core";
import { Observable } from "rxjs";
import { timeout } from "rxjs/operators";
import * as enviroment from "../../environments/environment";

export const DEFAULT_TIMEOUT = new InjectionToken<number>('defaultTimeout');

@Injectable({
    providedIn: 'root'
})

export class MiddlewareInterceptorService implements HttpInterceptor
{
    constructor (@Inject(DEFAULT_TIMEOUT) protected defaultTimeout: number) {}
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const timeoutValue = req.headers.get('timeout') || this.defaultTimeout;
        const timeoutValueNumeric = Number(timeoutValue); 
        if (req.url.includes(enviroment.middlewareEnv.domainV1)) {
            return next.handle(req).pipe(timeout(timeoutValueNumeric));
        }
        return next.handle(req);
    }
}