import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Actions, Effect, ofType } from "@ngrx/effects";
import { Store } from "@ngrx/store";
import { map, mergeMap, tap, withLatestFrom } from "rxjs/operators";
import * as fromApp from "src/app/store/app.reducer";
import * as AppInitActions from "./appinit.actions";
import * as enviroment from "src/environments/environment";
import { Router } from "@angular/router";
import { of } from "rxjs";

@Injectable()
export class AppInitEffects {
  @Effect({ dispatch: false })
  CheckRoomControl = this.actions$.pipe(
    ofType(AppInitActions.CHECK_R00M_CONTROL),
    withLatestFrom(this.store.select("appInit")),
    mergeMap(([actionData, appInit]) => {

      const body = new HttpParams().set("hash", "" + appInit.roomHash);
      this.store.dispatch(new AppInitActions.AppLoading());
      return this.http.post(enviroment.ocgEnv.domain + enviroment.ocgEnv.controlAuthenticateEp + appInit.roomId, body);
    }),
    map((resp) => {
      
      this.store.dispatch(new AppInitActions.AppLoaded());
      if ((resp as any).match === true) {
        
        // this.store.dispatch(new AppInitActions.AllowRoomControl());
        this.store.dispatch(new AppInitActions.NavigateRoomAreas());
      } else {
        //  this.router.navigate(["/", "error"]);
      }
    })
  );
  @Effect({ dispatch: false })
  navigateRoomAreas = this.actions$.pipe(
    ofType(AppInitActions.NAVIGATE_ROOM_AREAS),
    withLatestFrom(this.store.select("appInit")),
    mergeMap(([actionData, appInit]) => {
    if (appInit.roomAreaId !== null) {
      
      return of({
        actions: actionData,
        appInit
      })
    }
   }),
   tap(resp => {
     
     if (resp.appInit.roomAreaId !== undefined) {
      //  this.router.navigate(["/", "roomAreas", resp.appInit.roomAreaId])
     } else {
       
      //  this.router.navigate(["/", "roomAreas"]);
     }
     
   })
  );

  constructor(private actions$: Actions, private http: HttpClient, private store: Store<fromApp.AppState>, private router: Router) {}
}
